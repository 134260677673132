var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[(_vm.$route.name == 'purchaseDiscountsCreate')?_c('addHeader',{attrs:{"title":_vm.$t('purchaseDiscounts.form.addpurchaseDiscount'),"description":_vm.$t('purchaseDiscounts.form.FromHereYouCanCreateaNewPurchaseDiscount')}}):_vm._e(),(_vm.$route.name == 'purchaseDiscountsEdit')?_c('addHeader',{attrs:{"title":_vm.$t('purchaseDiscounts.form.purchasePaymentadjustment'),"description":_vm.$t('purchaseDiscounts.form.FromHereYouCanModifyThePurchaseDiscountInformation')}}):_vm._e(),_c('div',{staticClass:"mb-4 row"},[(_vm.$route.name == 'purchaseDiscountsCreate')?_c('connectionInput',{attrs:{"title":_vm.$t('purchaseDiscounts.form.discountNumber'),"group":_vm.$t('purchaseDiscounts.form.Groups'),"disabled":_vm.$user.admin ||
            _vm.$user.role.purchase_payments_edit_item
              ? false
              : true,"hasErorr":_vm.errors.invoice_group,"error":_vm.$t('purchaseDiscounts.form.ThisFieldIsRequired'),"values":_vm.$database.invoiceGroups},model:{value:(_vm.item.invoice_group),callback:function ($$v) {_vm.$set(_vm.item, "invoice_group", $$v)},expression:"item.invoice_group"}}):_vm._e(),(_vm.$route.name == 'purchaseDiscountsEdit')?_c('formInput',{attrs:{"title":_vm.$t('purchaseDiscounts.form.DiscountCode'),"disabled":true,"maxlength":"255"},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}):_vm._e(),_c('subFileInput',{attrs:{"title":_vm.$t('invoices.form.invoiceFile'),"file":_vm.item.file,"path":'invoices',"hasErorr":_vm.errors.file,"error":_vm.$t('allerts.ChooseASuitableFile')},model:{value:(_vm.item.file),callback:function ($$v) {_vm.$set(_vm.item, "file", $$v)},expression:"item.file"}}),_c('formInput',{attrs:{"title":_vm.$t('purchaseDiscounts.form.reference'),"maxlength":"255"},model:{value:(_vm.item.refrance),callback:function ($$v) {_vm.$set(_vm.item, "refrance", $$v)},expression:"item.refrance"}}),_c('selectInput',{attrs:{"title":_vm.$t('purchaseDiscounts.form.DiscountType'),"disabled":_vm.$route.name == 'purchaseDiscountsEdit' ? true : false,"values":[
            { name: _vm.$t('purchaseDiscounts.form.CustomerDiscount'), value: 0 },
            { name: _vm.$t('purchaseDiscounts.form.SupplierDiscount'), value: 1 },
            { name: _vm.$t('purchaseDiscounts.form.PartnerDiscount'), value: 2 } ]},model:{value:(_vm.item.client_type),callback:function ($$v) {_vm.$set(_vm.item, "client_type", $$v)},expression:"item.client_type"}}),(_vm.item.client_type == 0)?_c('searchGroupInput',{attrs:{"type":'customer',"values":_vm.$database.customers.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.purchaseDiscounts_customer_data}}):_vm._e(),(_vm.item.client_type == 1)?_c('searchGroupInput',{attrs:{"type":'supplier',"values":_vm.$database.suppliers.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.purchaseDiscounts_customer_data}}):_vm._e(),(_vm.item.client_type == 2)?_c('searchGroupInput',{attrs:{"type":'partner',"values":_vm.$database.partners.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.purchaseDiscounts_customer_data}}):_vm._e(),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(_vm._s(_vm.$t('purchaseDiscounts.form.date')))]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100 to-left"},[_vm._m(0),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('purchaseDiscounts.form.ChooseTimeAndDate'),"button-now-translation":_vm.$t('purchaseDiscounts.form.Now'),"disabled":_vm.$user.admin ||
                  _vm.$user.role.purchase_payments_edit_item
                    ? false
                    : true,"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)])]),_c('monyInput',{attrs:{"title":_vm.$t('purchaseDiscounts.form.PurchaseDiscountAmount'),"hasErorr":_vm.errors.cost,"error":_vm.$t('allerts.theAmountReceivedMustBeGreaterThan')},model:{value:(_vm.item.cost),callback:function ($$v) {_vm.$set(_vm.item, "cost", $$v)},expression:"item.cost"}}),(_vm.$site.projects_allow)?_c('searchGroupInput',{attrs:{"type":'project',"values":_vm.$database.projects,"inputs":[{ 'title':_vm.$t('purchaseDiscounts.form.Project'),'show': 'name'}]}}):_vm._e(),(_vm.$site.projects_allow)?_c('connectionInput',{attrs:{"disabled":_vm.item.project_id == 0,"title":_vm.$t('purchaseDiscounts.form.ProjectOutput'),"group":_vm.$t('purchaseDiscounts.form.ProjectOutputs'),"values":_vm.$database.projectItems.filter(function (el) { return el.project_id == _vm.item.project_id; })},model:{value:(_vm.item.project_item_id),callback:function ($$v) {_vm.$set(_vm.item, "project_item_id", $$v)},expression:"item.project_item_id"}}):_vm._e(),_c('selectInput',{attrs:{"title":_vm.$t('invoices.form.Situation'),"values":[
            { name: _vm.$t('invoices.draft'), value: 0 },
            { name: _vm.$t('invoices.approved'), value: 1 }
          ]},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}}),_c('dvider',{attrs:{"title":_vm.$t('purchaseDiscounts.form.discountNotes'),"description":_vm.$t('purchaseDiscounts.form.discountNotesDetails')}}),_c('formTextarea',{attrs:{"title":_vm.$t('purchaseDiscounts.form.thatAbout')},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('formTextarea',{attrs:{"title":_vm.$t('purchaseDiscounts.form.Note')},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}}),_c('hr',{staticClass:"mt-5 mb-5 col-12"}),(_vm.$route.name == 'purchaseDiscountsCreate' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('purchaseDiscounts.form.addDiscountOperation'))+" ")]):_vm._e(),(_vm.$route.name == 'purchaseDiscountsEdit' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('purchaseDiscounts.form.purchaseDiscountAdjustment'))+" ")]):_vm._e(),(_vm.$parent.stopEdit)?_c('loading'):_vm._e()],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])}]

export { render, staticRenderFns }